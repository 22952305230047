import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import ContactComponent from '../components/home/contact/index'
import GlobalStyle from '../styles/global'
import media from '../styles/media-query'

const SectionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  ${media.greaterThan('md')`
    display: none;
  `}
`

const ViewportElement = styled.div`
  height: 100vh;
`

const DesktopNotFound = styled.div`
  display: none;
  ${media.greaterThan('md')`
    display: block;
  `}
`

const ContactPage = () => {
  return (
    <main>
      <title>Contato</title>
      <GlobalStyle />
      <ViewportElement className="asscroll-container">
        <SectionsWrapper>
          <ContactComponent />
        </SectionsWrapper>
        <DesktopNotFound>
          <Link to="/">
            Página não encontrada, clique para ir para a página principal
          </Link>
        </DesktopNotFound>
      </ViewportElement>
    </main>
  )
}

export default ContactPage
